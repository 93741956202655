import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './src/styles/app.sass';

const queryClient = new QueryClient();
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>;
};
